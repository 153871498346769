<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #F2C98A;"
        :style="{
          backgroundImage: `url('${asideBg}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/lexicai/logos/logo-white.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: white;"
          >
            Panel de <br />Administración
          </h3>
        </div>
        <!-- <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
           :style="{backgroundImage: `url(${asideImg})`, marginTop: 20}"
        ></div> -->
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Bienvenido a Lexic.ai
                </h3>
              </div>

              <div
                role="alert"
                :class="{ show: errors.length }"
                class="alert fade alert-danger"
              >
                <div class="alert-text">
                  Usuario y/o contraseña inválidos, si olvidaste tu contraseña
                  da clic en: Olvidé mi contraseña
                </div>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Correo electrónico</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="username"
                    ref="username"
                    v-model="form.username"
                  />
                  <div
                    class="error text-primary"
                    v-if="!$v.form.username.required"
                  >
                    Este campo es obligatorio
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Contraseña</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    style="cursor:pointer"
                    @click="showForm('forgot')"
                    >¿Olvidaste tu contraseña?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    :type="'password'"
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    name="password"
                    ref="password"
                    v-model="form.password"
                  />
                  <div
                    class="error text-primary"
                    v-if="!$v.form.password.required"
                  >
                    Este campo es obligatorio
                  </div>
                </div>
              </div>
              <!-- <div class="pb-lg-0 pb-5">
                <vue-recaptcha :sitekey="recaptchaPublic" @verify="onVerify" @expired="onExpired"></vue-recaptcha>
              </div> -->
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  :disabled="$v.form.$invalid"
                >
                  Iniciar Sesión
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
              @submit.stop.prevent="onSubmitForgot()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  ¿Olvidaste tu contraseña?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Ingresa tu correo electronico, te enviaremos un correo de
                  recuperación.
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="femail"
                  placeholder="Correo electronico"
                  name="femail"
                  ref="femail"
                  v-model="recovery.email"
                />
                <div
                  class="error text-primary"
                  v-if="!$v.recovery.email.required"
                >
                  Este campo es obligatorio
                </div>
                <div class="error text-primary" v-if="!$v.recovery.email.email">
                  El email ingresado no es valido
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <vue-recaptcha
                  :sitekey="recaptchaPublic"
                  @verify="onVerify"
                  @expired="onExpired"
                ></vue-recaptcha>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary btn-lg my-3 mr-4"
                  @click="showForm('signin')"
                >
                  Volver
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_submit"
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary btn-lg my-3"
                  @click="onSubmitForgot()"
                  :disabled="$v.recovery.$invalid || !recaptchaPass"
                >
                  Recuperar
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <!-- <a href="#" class="text-primary font-weight-bolder font-size-h5"
            >Terms</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Plans</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Contact Us</a
          > -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import ApiService from "@/core/services/api.service";
import VueRecaptcha from "vue-recaptcha";
// FormValidation plugins
import { validationMixin } from "vuelidate";
import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import { required, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  name: "login-1",
  data() {
    return {
      resource: "admins/auth",
      state: "signin",
      // Remove this dummy login info
      form: {
        recaptchaToken: "",
        username: "",
        password: ""
      },
      recovery: {
        email: ""
      },
      recaptchaPass: false,
      recaptchaPublic: process.env.VUE_APP_RECAPTCHA_PUBLIC,
      locale: "es"
    };
  },
  components: { VueRecaptcha },
  validations: {
    form: {
      username: {
        required
      },
      password: {
        required
      }
    },
    recovery: {
      email: {
        required,
        email
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    asideBg() {
      return `${process.env.BASE_URL}media/lexicai/bg/home_bg.png`;
    },

    asideImg() {
      return `${process.env.BASE_URL}media/lexicai/items/home_geometrics.png`;
    }
  },
  mounted() {
    this.locale = localStorage.getItem("language") ? localStorage.getItem("language").toLowerCase() : this.locale;
  },
  methods: {
    onVerify(response) {
      console.log(response);
      this.form.recaptchaToken = response;
      this.recaptchaPass = true;
    },
    onExpired(response) {
      console.log(response);
      this.form.recaptchaToken = response;
      this.recaptchaPass = true;
    },
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    onSubmitLogin() {
      var username = this.form.username;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      if (submitButton)
        submitButton?.classList?.add(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      submitButton.disabled = true;

      // dummy delay
      setTimeout(() => {
        // send login request

        this.$store
          .dispatch(LOGIN, { username, password })
          // go to which page after successfully login
          .then(() => {
            //this.$store.dispatch(GET_ACCOUNT);
            this.$router.push({ name: "root" });
          })
          .catch(error => {
            console.error(error);
            // Swal.fire({
            //   title: "",
            //   text: "Usuario y/o contraseña inválidos, si olvidaste tu contraseña da clic en: Olvidé mi contraseña",
            //   icon: "error",
            //   confirmButtonClass: "btn btn-secondary",
            //   heightAuto: false
            // });
            if(submitButton)
            submitButton?.classList?.remove("spinner","spinner-light","spinner-right");
            submitButton.disabled = false;
          })
      }, 2000);
    },
    onSubmitForgot() {
      console.log("s onSubmitForgot");

      var email = this.recovery.email;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      if (submitButton)
        submitButton?.classList?.add(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      submitButton.disabled = true;

      // dummy delay
      setTimeout(() => {
        // send login request
        ApiService.post(`${this.resource}/recovery`, {
          email,
          recaptchaToken: this.form.recaptchaToken,
          lang: this.locale
        })
          // go to which page after successfully login
          .then(result => {
            Swal.fire({
              title: "",
              text:
                "Se te ha enviado un correo electrónico, con instrucciones para recuperar tu contraseña",
              icon: "success",
              heightAuto: false,
              timer: 1500
            });
            this.showForm("signin");
            console.log(result);
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
          })
          .catch(error => {
            Swal.fire({
              title: "",
              text: "No se ha encontrado el correo electrónico",
              icon: "error",
              position: "center",
              heightAuto: false
            });
            console.log(error);

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
          });
      }, 2000);
    }
  }
};
</script>
